export const field_codes = {
    delivery_options: {
        code: 'delivery_options',
        sku: {
            extreme_reach: 'extreme_reach',
            digital_file: 'digital_file'
        },
    },
    extreme_reach: {
        code: 'extreme_reach',
        sku: {
            over_night: 'over_night',
            one_hour: 'one_hour'
        }
    },
    call_letters_sys_codes: 'call_letters_sys_codes',
    digital_file: {
        code: 'digital_file',
        sku: {
            broadcast_hd_file: 'broadcast_hd_file',
            non_broadcast_hd_file: 'non_broadcast_hd_file'
        }
    },
    delivery_method: {
        code: 'delivery_method',
        sku: {
            hd_ftp_upload: 'hd_ftp_upload',
            td_express_email_delivery: 'td_express_email_delivery'
        }
    },
    digital_file_specs: 'digital_file_specs',
    digital_file_ftp_info: 'digital_file_ftp_info',
    td_express_delivery: 'td_express_delivery',


};

export const shipping_fields = {
        [field_codes.delivery_options.code]: '',
        [field_codes.extreme_reach.code]: '',
        [field_codes.call_letters_sys_codes]: '',
        [field_codes.digital_file.code]: '',
        [field_codes.delivery_method.code]: '',
        [field_codes.digital_file_specs]: '',
        [field_codes.digital_file_ftp_info]: '',
        [field_codes.td_express_delivery]: ''

    }
;
