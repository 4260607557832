import {LOGIN_SUCCESS, LOGIN_FAILURE} from "../components/LoginForm/actions";
import {request} from "../axiosRequest";
import {USER_LOGOUT, userLogout} from "../components/Logout/actions";
import NotFound from "../containers/NotFound";
import React from "react";

export const tokenMiddleware = store => next => action => {
    switch (action.type){
        case LOGIN_SUCCESS:
            window.localStorage.setItem('customerAuthToken', action.data.customerAuthToken);
            window.localStorage.setItem('customerEmail', action.data.customerEmail);
            request.setToken(action.data.customerAuthToken);
            console.log('From middleware ' + action.data);
            break;
        case LOGIN_FAILURE:
        case USER_LOGOUT:
            window.localStorage.removeItem('customerAuthToken');
            window.localStorage.removeItem('customerEmail');
            request.setToken(null);
            break;
        default:
            break;
    }
    next(action);
};

export const authInterceptor = ({ dispatch }) => next => action => {
    console.log('dispatch', dispatch);
    console.log('action', action);
    console.log('next', action);
    if(action.error){
        if (action.type !== 'UPDATE_PASSWORD_FAILURE' && (action.error.code === 401 || (action.error.response && action.error.response.status === 401))) {
            if(action.type === LOGIN_FAILURE)
            {
                return next(action);
            }
            console.log(action);
            dispatch(userLogout());
            console.log("auth.....")
        }
        if(typeof action.error == "string") {
            return next(action);
        }
        if(action.error || action.error.code === 400 || (action.error.code === 401 && action.type === 'UPDATE_PASSWORD_FAILURE')) {
            if(action.error && action.error.message) {
                action.error =  parseMessages(action.error);

            } else {
                action.error = parseMessages(action.error.data);
            }
            return next(action);
        }
        // if(action.error.code === 404 || (action.error.response && action.error.response.status === 404)) {
        //     return next(action);
        // }
        if(action.error.response) {
            let response = action.error.response;
            if(response) {
                let data = response.data;
                if(data) {
                    if(typeof data.data == "object") {
                        if(data.data.message) {
                            action.error = data.data.message;
                            return next(action);
                        }
                    }
                    if(typeof data.data == "string") {
                        action.error = data.data;
                        return next(action);
                    }
                }
            }
        }

        action.error = "We are having problems with the server. Please try later.";
        return next(action);

    }
    else{
        return next(action);
    }
};

let parseMessages = (errorData) => {
    console.log('errorData', errorData)
    let message = errorData.message + '\n';
    let constructErrorsString = (error) => {
        let message = error.message;
        let parameters = error.parameters;
        if(Array.isArray(parameters)) {
            for (let i = 0; i < parameters.length; i += 1) {
                let index = i + 1;
                message = message.replace(`%${index}`, parameters[i])
            }
        } else if(typeof parameters === "object") {
            Object.keys(parameters).map((paramKey) => {
                message = message.replace(`%${paramKey}`, parameters[paramKey])
            })
        }
        return message;
    }
    if(errorData) {
        if(errorData.errors) {
            let errors = errorData.errors.map(error => {
                return constructErrorsString(error)
            })
            return message + errors.join('\n');
        }
        if(errorData.parameters) {
            return constructErrorsString(errorData);
        }
        if(!errorData.parameters) {
            return errorData.message;
        }
    }
    return '';
}
