import {
    CATEGORY_IMAGES_SERVICES, CDN_URL,
    CUTS_LENGTH_AMOUNT,
    FIELDS_CODE, getFieldsByProductSku, isPkgProduct,
    LENGTH_INFORMATION,
    PRODUCTS_FIELDS, SHARED_INFORMATION
} from "./actions/Constants";

export const validEmailRegex =
    RegExp(/^((([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}),?\s?)+$/i);

export const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
};

export const getOptionTitleByCode = (product, code) => {
    if(product && product.options) {
        let options = product.options
        let option = getOptionByCode(options, code)
        if(option) {
            return option.title
        }
    }
    return '';
}

export const getOptionAllowedExtensionByCode = (product, code) => {
    if(product && product.options) {
        let options = product.options
        let option = getOptionByCode(options, code)
        if(option) {
            let allowedExtensions =  option.file_extension;
            allowedExtensions = allowedExtensions.split(',');
            allowedExtensions = allowedExtensions.map(item => {
                return item.trim();
            })
            return allowedExtensions;
        }
    }
    return [];
}
export const getOptionAllowedExtension = (product, code) => {
    if(product && product.options) {
        let options = product.options
        let option = getOptionByCode(options, code)
        if(option) {
            return option.allowed_extensions
        }
    }
    return '';
}
export const getOptionValueTitle = (product, code, valueSku) => {
    if(product && product.options) {
        let options = product.options
        let option = getOptionByCode(options, code)
        if(option && option.type === 'checkbox') {
            let values = option.values;
            for(let valueIndex in values) {
                let value = values[valueIndex];
                if(value.sku === valueSku) {
                    return value.title
                }
            }
        }
    }
    return '';
}

export const getOptionValuePrice = (options, code, valueSku) => {
        let option = getOptionByCode(options, code)
        if(option && option.type === 'checkbox') {
            let values = option.values;
            for(let valueIndex in values) {
                let value = values[valueIndex];
                if(value.sku === valueSku) {
                    return value.price
                }
            }
        }
    return 0;
}

export const getOptionByCode = (optionList, optionCode) => {
    let optionToReturn = null;
    if(optionList){
        optionList.map((option) => {
            if(option.extension_attributes && option.extension_attributes.option_code == optionCode) {
                optionToReturn = option;
            }
        });
    }
    return optionToReturn;
};

export const getCategoryCustomOption = (optionList) => {
    let categoryCode = FIELDS_CODE.category_id;
    let categoryOption = getOptionByCode(optionList, categoryCode);
    let categoryInfo = {};
    if(categoryOption) {
        categoryInfo = categoryOption.extension_attributes.category_info;
        if(categoryInfo) {
            console.log('categoryInfo', categoryInfo);
            categoryInfo = JSON.parse(categoryInfo);
            if(!categoryInfo.category_background_image || categoryInfo.category_background_image == '') {
                categoryInfo.category_background_image = `${CDN_URL}assets/backgrounds/bg-order.jpg`;
            }
            else {
                categoryInfo.category_background_image = CATEGORY_IMAGES_SERVICES + 'backgroundImage/' + categoryInfo.category_background_image
            }
        }
    }
    return categoryInfo;
};
let setupInformation = (item, information, index = null) => {
    let info = {...information};
    // console.log('setupInformation', info)
    if(item.product_option && item.product_option.extension_attributes &&
        item.product_option.extension_attributes.custom_options) {
        let productCustomOptions = item.product_option.extension_attributes.custom_options;
        for(let field in info) {
            let optionCodeLessIndex = field;
            let optionCode = field;
            if (index) {
                optionCode += `_${index}`
            }
            for (let customOptionIndex in productCustomOptions) {
                let customOption = productCustomOptions[customOptionIndex];
                // console.log('customOption', customOption)
                if(customOption.extension_attributes && customOption.extension_attributes.option_code){
                let customOptionCode = customOption.extension_attributes.option_code;
                let customOptionValue = customOption.option_value;
                let customOptionType = customOption.extension_attributes.option_type;
                let customOptionQty = customOption.extension_attributes.option_qty;
                    if (customOptionCode == optionCode) {
                        switch (customOptionType) {
                            case 'checkbox':
                            case 'radio':
                            case 'drop_down':
                                let values = customOptionValue.split(',');
                                let valuesInfo = JSON.parse(customOption.extension_attributes.values_info);
                                console.log('valueeeee', valuesInfo);
                                values.map((value) => {
                                    // console.log('Check', value);
                                    // console.log('Check', values);
                                    // console.log('Code', optionCode);
                                    if (customOptionType === 'radio' || customOptionType === 'drop_down') {
                                        if(valuesInfo[value]) {
                                            info[optionCodeLessIndex] = valuesInfo[value].sku;
                                        }
                                        if(customOptionQty) {
                                            customOptionQty = JSON.parse(customOptionQty);
                                            if(value in customOptionQty && customOptionQty[value] != undefined) {
                                                info[optionCodeLessIndex + '_qty'] = customOptionQty[value];
                                            }
                                        }
                                    } else {
                                        if (value in valuesInfo) {
                                            // if (valuesInfo[value].sku in info) {
                                            info[valuesInfo[value].sku] = true;
                                            if (info[optionCodeLessIndex]) {
                                                info[optionCodeLessIndex][valuesInfo[value].sku] = true;
                                            } else {
                                                info = {
                                                    ...info,
                                                    [optionCodeLessIndex]: {[valuesInfo[value].sku]: true}
                                                }
                                            }
                                            // }
                                        }
                                        if(customOptionQty) {
                                            // console.log('customOptionQty', customOptionQty)
                                            if(typeof customOptionQty == "string"){
                                                customOptionQty = JSON.parse(customOptionQty);
                                            }
                                            if(value in customOptionQty && customOptionQty[value] != undefined) {
                                                // console.log('customOptionQty __', valuesInfo[value].sku + '_qty')
                                                info[valuesInfo[value].sku + '_qty'] = parseInt(customOptionQty[value]);
                                            }
                                        }
                                    }
                                })
                                break;
                            case 'date_time':
                                let date = customOptionValue;
                                if(typeof date == "string") {
                                    date = date.split(',');
                                    date = date.slice(-1)[0] ;
                                } else if(Array.isArray(date)) {
                                    date = date[0];
                                }
                                info[optionCodeLessIndex] = date;
                                break;
                            default:
                                info[optionCodeLessIndex] = customOptionValue;
                        }
                    }
                }
            }

        }
    }
    return {...info};
};

let getRelatedItemsByType = (cartItem, cartItems, product_type) => {
    let shippingItems = [];
    if(cartItem.sku.includes('Shipping') || cartItem.name.includes('Shipping')) {
        shippingItems.push(cartItem);
        return shippingItems;
    }
    for(let i = 0; i < cartItems.length; i++) {
        let tempCartItem = cartItems[i];
        console.log('tempCartItem', tempCartItem)
        if(cartItem.extension_attributes.group_item && tempCartItem.extension_attributes.group_item === cartItem.extension_attributes.group_item
            && tempCartItem.item_id !== cartItem.item_id && tempCartItem.extension_attributes.product_type === product_type) {
            shippingItems.push(tempCartItem);
        }
    }
    return shippingItems;
};

export const checkIfCartItemHasSharedFields = (cartItem) => {
    let originalProduct = cartItem.extension_attributes.original_product;
    return checkIfProductHasSharedFields(originalProduct);
}
export const checkIfProductHasSharedFields = (product) => {
    if(product) {
        let productOptions = product.options;
        for(let poIndex in productOptions) {
            let productOption = productOptions[poIndex];
            if(productOption.extension_attributes.option_code in SHARED_INFORMATION) {
                return true
            }
        }
    }
    return false
}

export let splitItemCartIntoOrderProcessInformation = (cartItem, cartItems = []) => {
    let lengthToReturn = null;
    let sharedInformation = null;
    let showInformation = null;
    let customOptionsProductInformation = null;

    let lengthInformation = {
        ...LENGTH_INFORMATION
    };

    showInformation = cartItem.extension_attributes.show_information;
    console.log('showInformation', showInformation)

    if(checkIfCartItemHasSharedFields(cartItem)) {
        sharedInformation = {
            ...SHARED_INFORMATION
        };
        sharedInformation = setupInformation(cartItem, sharedInformation);
    }
    let productName = cartItem.sku;
    if(cartItem.extension_attributes
      && cartItem.extension_attributes.original_product
      && cartItem.extension_attributes.original_product.sku) {
        productName = cartItem.extension_attributes.original_product.sku
    }
    productName = productName.replace(/ /g, "_");
    productName = productName.toUpperCase();

    let productFields = PRODUCTS_FIELDS[productName];
    console.log('Products fields ', productFields, productName, productName.indexOf('-', 1));
    if(!cartItem.sku.includes('Shipping') && !cartItem.name.includes('Shipping')) {
        customOptionsProductInformation =  setupInformation(cartItem, productFields);
    }
    console.log("customOptionsProductInformation", customOptionsProductInformation);
    let shippingItems = getRelatedItemsByType(cartItem, cartItems, 'shipping');
    console.log("shippingItems", shippingItems);

    let shippingProductsToReturn = shippingItems?getShippingInformation(cartItem, shippingItems):null;

    let cutItems = getRelatedItemsByType(cartItem, cartItems, 'cut');
    console.log('cut items', cutItems);
    let cutProductsToReturn = getCutsInformation(cutItems, cartItems);
    console.log('lengthInformation', cutProductsToReturn);
    return {
        showInformation: showInformation,
        lengthInformation: cutProductsToReturn,
        sharedInformation: sharedInformation,
        customOptionsInformation: customOptionsProductInformation,
        shippingInformation: shippingProductsToReturn
    }
};

const getCutsInformation = (cutItems, cartItems) => {
    let realCutProducts;
    for (let cartItemIndex in cartItems) {
        let cartItem = cartItems[cartItemIndex];
        if(cartItem.extension_attributes && checkIsMainProduct(cartItem.extension_attributes.product_type)) {
            let originalProduct = cartItem.extension_attributes.original_product;
            if(originalProduct && originalProduct.extension_attributes.cut_products) {
                realCutProducts = originalProduct.extension_attributes.cut_products;
            }
        }
    }

    let cutsInfo = cutItems.map((cutItem) => {
        let productFields = {...LENGTH_INFORMATION};
        let cutInformation = setupInformation(cutItem, productFields);
        cutInformation['item_id'] = cutItem.item_id;
        cutInformation['group_item'] = cutItem.extension_attributes.group_item;
        for (let realProductIndex in realCutProducts) {
            let realProduct = realCutProducts[realProductIndex];
            if(realProduct.cut_product === cutItem.extension_attributes.product_id) {
                cutInformation['cut_type'] = realProduct.cut_type;
                if(realProduct.cut_type === 'first'){
                    cutInformation['isFirst'] = true;
                }
            }
        }
        if(cutInformation.cuts === '' || !cutInformation.cuts) {
            cutInformation.cuts = 'none';
        }
        return  {[cutInformation.cuts]: cutInformation};
    })
    return cutsInfo
}

const getShippingInformation = (cartItem, shippingItems) => {
    if(
        cartItem.extension_attributes.original_product
        && cartItem.extension_attributes.original_product.extension_attributes
        && !cartItem.extension_attributes.original_product.extension_attributes.shipping
        && cartItem.extension_attributes.original_product.extension_attributes.product_type.id !== 'shipping'
    )
    {
        return null;
    }
    let shippingProductsToReturn = shippingItems.map((shippingItem) => {
        let shippingName = shippingItem.name;
        shippingName = shippingName.replace(/ /g, "_");
        shippingName = shippingName.toUpperCase();
        let productFields = PRODUCTS_FIELDS[shippingName];
        console.log("productFields", shippingName);
        let shippingInformation = setupInformation(shippingItem, productFields);
        shippingInformation['item_id'] = shippingItem.item_id;
        shippingInformation['group_item'] = shippingItem.extension_attributes.group_item;
        return  shippingInformation;
    })
    if(shippingProductsToReturn.length === 0) {
        if(cartItem.extension_attributes
            && cartItem.extension_attributes.original_product
            && cartItem.extension_attributes.original_product.extension_attributes
            && cartItem.extension_attributes.original_product.extension_attributes.shipping
            && cartItem.extension_attributes.original_product.extension_attributes.shipping.name
        ) {
            let shippingName = cartItem.extension_attributes.original_product.extension_attributes.shipping.name;
            let productFields = PRODUCTS_FIELDS[shippingName];
            shippingProductsToReturn.push(productFields);
        }
    }
    return shippingProductsToReturn;
}


export const getShowInformationFromProduct = (product, options) => {
    let optionWithCodeInformation = {
        [FIELDS_CODE.show_date]: '',
        [FIELDS_CODE.show_time]: '',
        [FIELDS_CODE.special_guests]: '',
        [FIELDS_CODE.venue_capacity.code]: '',
        [FIELDS_CODE.venue_name]: '',
        [FIELDS_CODE.ticket_locations]: '',
        [FIELDS_CODE.city_market_name]: '',
        [FIELDS_CODE.mentions.sku.mention_reserved_seats]: false,
        [FIELDS_CODE.mentions.sku.mention_general_admission]: false,
        [FIELDS_CODE.mentions.code]: {}
    };

    for(let optionIndex in options) {
        let option = options[optionIndex];

        for(let productCustomOptionIndex in product.options) {

            let customOption = product.options[productCustomOptionIndex];

            let optionCode = customOption.extension_attributes.option_code;

            if(customOption.option_id == option.option_id) {
                let optionType = customOption.type;
                let optionValue = option.option_value;
                if(optionType === 'checkbox' || optionType === 'radio') {
                    let optionValues = optionValue.split(',');
                    for(let valueIndex in optionValues) {
                        let optionValue = optionValues[valueIndex];
                        let value = getValueOptionByOptionValueId(optionValue, customOption.values)
                        if(value) {
                            optionWithCodeInformation[value.sku] = true
                        }

                    }
                }
                else {
                    optionWithCodeInformation[optionCode] = optionValue;
                }
            }
        }
    }
    return optionWithCodeInformation;
}

let getValueOptionByOptionValueId = (optionValueId , values) => {
    for(let valueIndex in values) {
        let value = values[valueIndex];
        if(value.option_type_id == optionValueId) {
            return value
        }
    }
    return null;
};

export const _getAvailableRegions = (country_id, countries) => {
    for(let countryIndex in countries) {
        let country = countries[countryIndex];
        if(country.id == country_id) {
            return country.available_regions
        }
    }
    return null;
};

export const getInfoToShow = (item) => {
    let product = item;
    let info = {
        show_time: '',
        show_date: '',
        venue_name: '',
        city_market_name: '',
        special_guests: '',
        special_instructions: '',
    };
    let show_information = product.extension_attributes.show_information;

    if(show_information && show_information.length > 0) {
        show_information = show_information[0]
    }
    else{
        show_information = info;
    }
    return show_information;
}


export const getShowPrice = (shows, productName) => {
    if(productName.toLowerCase().includes('theatrical')) {
        return 0;
    } else {
        let totalPrice = 0;
        if(shows && shows.length > 1) {
            totalPrice += (shows.length - 1)*85;
        }
        return totalPrice;
    }
}

/**
 *
 * @param selectedProducts
 * @param productOptionsArray
 * @param shows
 * @returns {number}
 */
export const getTotalPrice = (selectedProducts, productOptionsArray, shows) => {

    let totalPrice = 0;

    for(let index in selectedProducts) {
        console.log('selectedProducts', selectedProducts)
        console.log('productOptionsArray', productOptionsArray)
        let product = selectedProducts[index];
        if(product) {
            totalPrice += product.price;
            totalPrice += getCustomOptionsPrice(product, productOptionsArray[product.sku]);
            totalPrice += getShowPrice(shows, product.sku);
        }
    }
    totalPrice = parseFloat(Math.round(totalPrice * Math.pow(10, 2)) /Math.pow(10,2)).toFixed(2);

    return parseFloat(totalPrice);
}
const getCustomOptionsPrice = (product, customOptions) => {
    let price = 0;

    for(let pOptionIndex in product.options) {
        let productOption = product.options[pOptionIndex];
        // console.log('Product option', product.name, customOptions)
        for(let customOptionIndex in customOptions) {
            let customOption = customOptions[customOptionIndex];
            // console.log('customOption', customOption)
            // console.log('productOption.extension_attributes.option_code', productOption.extension_attributes.option_code)
            if(productOption.extension_attributes &&
                customOption &&
                customOption[productOption.extension_attributes.option_code]) {
                if(productOption.price) {
                    console.log('Option Code price 1', productOption.extension_attributes.option_code, productOption.price)
                    if(productOption.extension_attributes.has_qty) {
                        if(customOption[productOption.extension_attributes.option_code + '_qty'])
                        {
                            let optionQty = customOption[productOption.extension_attributes.option_code + '_qty'];
                            if (typeof optionQty == "number") {
                                price += optionQty*productOption.price;
                            }
                        }
                    }
                    else {
                        price += productOption.price;
                    }
                }
                console.log('---------values---------------')
                if(productOption.type =='radio' || productOption.type =='drop_down') {
                    let optionValues = productOption.values;
                    console.log('optionValues', optionValues)
                    for(let optionValueIndex in optionValues) {
                        let optionValue = optionValues[optionValueIndex];
                        if(customOption[productOption.extension_attributes.option_code] == optionValue.sku && optionValue.price) {
                            console.log('value sku', optionValue.sku);
                            price += getPriceForNonTextBox(productOption, customOption, optionValue);
                        }
                    }
                }
                // console.log('------------values---------------')
                if(productOption.type =='checkbox') {
                    // console.log('custom option checkbox');
                    let optionValues = productOption.values;
                    for(let optionValueIndex in optionValues) {
                        let optionValue = optionValues[optionValueIndex];
                        if(customOption[productOption.extension_attributes.option_code] &&
                            customOption[productOption.extension_attributes.option_code][optionValue.sku] &&
                            optionValue.price) {
                            price += getPriceForNonTextBox(productOption, customOption, optionValue);

                        }
                    }
                }
            }
        }
    }
    return price;
};

const getPriceForNonTextBox = (productOption, customOption, optionValue) => {
    let price = 0.00;
    if(productOption.extension_attributes.has_qty) {
        // console.log('custom option qty', optionValue.sku);
        if(customOption[optionValue.sku + '_qty'] != undefined)
        {
            let optionQty = customOption[optionValue.sku + '_qty'];
            console.log('optionQty', optionQty)
            try {
                optionQty = parseInt(optionQty);
                if(optionQty && typeof optionQty == "number") {
                    price += optionQty*optionValue.price;
                }
                else
                {
                    price += optionValue.price;
                }
            }
            catch (e) {
                price += optionValue.price;
            }
        }
    }
    else {
        price += optionValue.price;
    }
    console.log('Option Code price 2', productOption.extension_attributes.option_code, price, productOption.option_id, productOption.sort_order)
    return price;
}

export const handleForCustomCheckbox = (info, state) => {
    let code = info.code;

    if (!info.type || info.type === "checkbox") {
        let initialValue = state[code];
        state[code] = !state[code];
        let fieldCode = info['field-code'];
        if (state[fieldCode]) {
            state[fieldCode][code] = state[code];
        } else {
            state[fieldCode] = {[code]: state[code]};
        }
        let qty = code + "_qty";
        console.log('initial value',initialValue, code, qty)
        if(qty in state){
            if(!initialValue) {
                state[qty] = 1;
            }
            else {
                state[qty] = 0;
            }
        }
    }
    else {
        state[code] = info.value
    }
    return state;
}
export const getAdmatFilesFromCategoryInfo = (categoryInfo, selectedProducts) => {
    if(categoryInfo) {
        if(categoryInfo.admat_info) {
            let admat = categoryInfo.admat_info;
            let admatCopy = admat.filter((value) => {
                if(value.product_id in selectedProducts) {
                    return value
                }
            })
            return admatCopy;
        }
    }
    return [];

}
export const getAdmatInfo = (cartItem) =>
{
    let getConfigSelected = () => {
        if(cartItem.product_option) {
            let customOptions = cartItem.product_option.extension_attributes.custom_options;
            if(customOptions) {
                for (let index in customOptions) {
                    let option = customOptions[index];
                    if(option.extension_attributes) {
                        let optionCode = option.extension_attributes.option_code;
                        if(optionCode === "art_product_config_id") {
                            return option.option_value;
                        }
                    }
                }
            }
        }
        return null;
    };
    let categoryInfo = {};
    let admat = [];
    if(cartItem.product_option) {
        let customOptions = cartItem.product_option.extension_attributes.custom_options;
        let productId = cartItem.extension_attributes.product_id;
        if(customOptions) {
            customOptions.map((option) => {
                if(option.extension_attributes) {
                    let optionCode = option.extension_attributes.option_code;
                    if(optionCode === FIELDS_CODE.category_id) {
                        categoryInfo = option.extension_attributes.category_info;
                        if(categoryInfo) {
                            categoryInfo = JSON.parse(categoryInfo);
                            admat = categoryInfo.admat_info;
                            if(Array.isArray(admat)) {
                                let selecteArtProductConfigId = getConfigSelected();
                                admat = admat.filter((item) =>  {
                                    console.log('admatInfo', item.product_config_id, selecteArtProductConfigId);
                                    if(item.product_config_id == selecteArtProductConfigId) {
                                        return item
                                    }
                                })
                            }
                        }
                    }
                }
            })
        }
    }
    console.log(admat)
    return admat;
}

export const capitalizeFirstLetter = (text) => {
    if(text != "") {
        text = text.charAt(0).toUpperCase() + text.slice(1, text.length)
    }
    return text
}

export const getCategoryInfoFromOrder = (order) => {
    let categoryData = {};
    for (let index in order.items) {
        let item = order.items[index];
        let categoryInfo = getCategoryInfoFromItemOptions(item);
        if(categoryInfo) {
            categoryData = categoryInfo;
        }
    }
    return categoryData;
}

export const getCategoryInfoFromItemOptions = (item) => {
    let productOptions = item.product_option;
    let categoryData = null;
    if(productOptions) {
        let extensionAttributes = productOptions.extension_attributes;
        if(extensionAttributes && extensionAttributes.custom_options) {
            let customOptions = extensionAttributes.custom_options;
            customOptions.map((option) => {
                if(option.extension_attributes && option.extension_attributes.category_info) {
                    let categoryInfo = option.extension_attributes.category_info
                    if(categoryInfo) {
                        categoryInfo = JSON.parse(categoryInfo);
                        categoryData = categoryInfo
                    }
                }
            })
        }
    }
    return categoryData;
}

export const getCutFromSelectedProducts = (venueCapacity, cutType, selectedProducts) => {
    let firstProductsCut = {};
    for(let index in selectedProducts) {
        let selectedProduct = selectedProducts[index];
        if(selectedProduct && selectedProduct.extension_attributes && selectedProduct.extension_attributes.cut_products) {
            let cutProducts = selectedProduct.extension_attributes.cut_products;
            for (let cutIndex in cutProducts) {
                let cutProduct = cutProducts[cutIndex];
                if(cutProduct.cut_type === cutType && cutProduct.venue == venueCapacity) {
                    firstProductsCut[selectedProduct.id] = cutProduct.real_cut_product;
                }
            }
        }
    }
    return firstProductsCut;
}


export const checkIsMainProduct = (product_type) => {
    let main_types = [
     'video',
     'audio',
     'art',
     'album',
     'online',
     'broadcast_television',
     'broadcast_radio',
     'revision_product'];
    if(main_types.indexOf(product_type) !== -1) {
        return true;
    }
    return false;
}

export const getAllCutsFromSelectedProducts = (venueCapacity, selectedProducts) => {
    let cutAllProducts = {};
    for(let index in selectedProducts) {
        let selectedProduct = selectedProducts[index];
        if(selectedProduct && selectedProduct.extension_attributes && selectedProduct.extension_attributes.cut_products) {
            let cutProducts = selectedProduct.extension_attributes.cut_products;
            for (let cutIndex in cutProducts) {
                let cutProduct = cutProducts[cutIndex];
                let cutType = cutProduct.cut_type;
                if(cutProduct.venue == venueCapacity || cutProduct.venue == 0) {
                    if(selectedProduct.id in cutAllProducts) {

                        if(!(cutType in cutAllProducts[selectedProduct.id])) {
                            cutAllProducts[selectedProduct.id][cutType] = cutProduct.real_cut_product
                        }
                    }
                    else {
                        cutAllProducts = {
                            ...cutAllProducts,
                            [selectedProduct.id]: {
                                [cutType]: cutProduct.real_cut_product
                            }
                        }
                    }

                }
            }
        }
    }
    return cutAllProducts;
}

export const getNextCutProduct = (productId, allCutProducts, lengthInfo, productSku) => {
    if(allCutProducts && productId in allCutProducts) {
        let cutType = getCutTypeProduct(lengthInfo, productSku);
        if(allCutProducts[productId] && cutType in allCutProducts[productId]) {
            return allCutProducts[productId][cutType]
        }
    }
    return undefined;
}
export const getCutTypeFromAllLengths = (count, productSku) => {
    switch (productSku.toUpperCase()) {
        case 'HD TV':
        case 'THEATRICAL HD TV':
        case 'SD TV':
        case 'ONLINE VIDEO':
        case 'THEATRICAL ONLINE VIDEO':
        case 'ONLINE AUDIO':
        case 'AMEX TV':
        case 'AMEX HD TV':
        case 'ALBUM VIDEO':
        case 'ALBUM AUDIO':
        case 'INTERNATIONAL TV':
            if(count === 0) {
                return 'first'
            }
            else {
                return 'secondary_paid'
            }
        case 'INTERNATIONAL RADIO':
        case 'RADIO SPOT':
        case 'THEATRICAL RADIO SPOT':
        case 'AMEX RADIO SPOT':
           if(count === 0) {
               return "first"
           }
           if(count < 4) {
               return "secondary_free";
           }
           else {
               return "secondary_paid";
           }
    }
}
export const getCutTypeProduct = (lengthInfo, productSku) => {
    if(!lengthInfo) {
        return 'first';
    }

    switch (productSku.toUpperCase()) {
        case 'HD TV':
        case 'THEATRICAL HD TV':
        case 'SD TV':
        case 'ONLINE VIDEO':
        case 'THEATRICAL ONLINE VIDEO':
        case 'ONLINE AUDIO':
        case 'AMEX TV':
        case 'AMEX HD TV':
            if(!lengthInfo) {
                return 'first'
            }
            if(lengthInfo.isFirst) {
                return 'first'
            }
            else {
                return 'secondary_paid'
            }
            break;
        case 'RADIO SPOT':
        case 'THEATRICAL RADIO SPOT':
        case 'AMEX RADIO SPOT':
            if(!lengthInfo) {
                return 'first'
            }
            let allCuts = [];
            for (let index in lengthInfo) {
                let cut = lengthInfo[index];
                for(let cutDataIndex in cut) {
                    allCuts.push(cut[cutDataIndex])
                }
            }
            let count = 0;
            for (let cutsIndex in allCuts) {
                let cutValue = allCuts[cutsIndex];
                if(cutValue.first) {
                    return "first";
                }
                if(cutValue.first && count > 4) {
                    return "secondary_paid";
                }
                else {
                    if (count <= 4) {
                        return "secondary_free";
                    }
                }
            }
    }
    return undefined;
}

export const setCutTypeToAllCutLength = (cutLengths, productSku) => {
    let count = 0;

    // Count the cuts for each length
    let lengthCount = [];

    for(let lengthIndex in cutLengths) {
        console.log('product in lengthIndex....', lengthIndex)
        let lengthCutType = cutLengths[lengthIndex]
        for(let cIndex in lengthCutType) {
            console.log('product in lengthCutType....', lengthCutType)
            let lengthData = lengthCutType[cIndex];

            // Initialize count for cut length if it doesn't exist.
            if (lengthCount[lengthData.length] === undefined) {
                lengthCount[lengthData.length] = 0;
            }

            // Increment
            lengthCount[lengthData.length]++;

            // If product is a radio spot, count by cut length, not just cut total.
            //Looks like this how it should work for tv products too
            let productList = ['Radio Spot','AMEX Radio Spot','Theatrical Radio Spot',
                'HD TV',  'Theatrical HD TV', 'SD TV', 'Online Video', 'Theatrical Online Video',
                'Online Audio', 'AMEX TV', 'AMEX HD TV'];

            let variableCount = (productList.includes(productSku))
              ? lengthCount[lengthData.length] - 1
              : count;

            let cutType = getCutTypeFromAllLengths(variableCount, productSku);
            console.log('cuttype', cutType);
            lengthData['cut_type'] = cutType;
            lengthCutType[cIndex] = lengthData;
            count += 1;
        }
        cutLengths[lengthIndex] = lengthCutType;
    }
    return cutLengths;
}

export const getFieldsToShowOnVenueSection = (selectedProducts) => {
    let allFields = [];
    for (let index in selectedProducts) {
        let selectedProduct = selectedProducts[index];
        allFields = [...allFields, ...getFieldsByProductSku(selectedProduct.sku)]
    }
    return allFields
}

export const numberFormat = (number) => {
    if(number !== null) {
        return number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        })
    } else {
        return `$0.00`;
    }
}

export const getCustomAttributeValue = (product, attribute_code) => {
    let customAttributes = product.custom_attributes;
    for(let index in customAttributes) {
        let customAttribute = customAttributes[index];
        if(customAttribute.attribute_code === attribute_code) {
            return customAttribute.value;
        }
    }
    return null;
}

export const getProductLabel = (item) => {
    if(isPkgProduct(item.sku)) {
        let description = getCustomAttributeValue(item, 'short_description');
        if (description) {
            return item.name + ' -' + `&nbsp;<span style="color: #00b1e8;">${description}</span>`;
        }
    }
    return item.name
}
