import React from 'react';
import {connect} from "react-redux";
import Recaptcha from "react-recaptcha"
import {createAccountAttempt, createAccountAttemptCleanUp} from "./actions";
import {Link, withRouter} from "react-router-dom";
import {_getAvailableRegions, validEmailRegex} from "../../helpers"
import {getCountries} from "../Countries/actions";
import {CAPTCHA_SITE_KEY} from "../../actions/Constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const mapDispatchToProps = {
    createAccountAttempt,
    createAccountAttemptCleanUp,
    getCountries
};

const mapStateToProps = state => ({
    ...state.createAccount,
    ...state.countriesData
})


class CreateAccountForm extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            username: "",
            password: '',
            passwordConfirm: '',
            firstName: '',
            lastName: '',
            middleName: '',
            errors: {},
            availableRegions: [],
            address: {
                region: {
                    region_code:"",
                    region:"",
                    region_id:""
                },
                region_id:"",
                country_id:"US",
                street:[],
                telephone:"",
                postcode:"",
                zip_plus: "",
                city:"",
                firstname:"",
                lastname:"",
                id: null,
                default_billing: true,
                company: '',
                fax: ''
            },
            recaptchaRef: React.createRef(),
            validCaptcha: false
        };

        this.createAccount = this.createAccount.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
        this.captchaVerifyCallBack = this.captchaVerifyCallBack.bind(this);
        this.expiredCallback = this.expiredCallback.bind(this);
    }
    componentDidMount() {
        if(this.state.recaptchaRef){
            this.state.recaptchaRef.current.reset();
        }
        let countries = this.props.countries;
        if(!countries) {
            this.props.getCountries()
        }
        else {
            let address = {...this.state.address};
            address.country_id = 'US';
            let availableRegions = _getAvailableRegions(address.country_id, this.props.countries);
            this.setState({
                address: {...address},
                availableRegions: availableRegions
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.recaptchaRef != this.state.recaptchaRef) {
            this.state.recaptchaRef.current.reset();
        }
        if(prevProps.success != this.props.success && this.props.success) {
            this.props.history.push('/account/login');
        }
        console.log('prevState', prevState.address);
        console.log('this.state', this.state.address)
        if(prevState.address.country_id != this.state.address.country_id) {
            let availableRegions = _getAvailableRegions(this.state.address.country_id, this.props.countries);
            console.log('availableRegions', availableRegions)
            this.setState({
                availableRegions: availableRegions
            })
        }

        if(prevProps.createAccountError != this.props.createAccountError && this.props.createAccountError != null) {
            if(this.state.recaptchaRef.current) {
                this.state.recaptchaRef.current.reset();
            }
        }
        if(prevProps.countries != this.props.countries) {
            let availableRegions = _getAvailableRegions('US', this.props.countries);
            this.setState({
                availableRegions: availableRegions
            })
        }
    }
    componentWillUnmount() {
        if(this.state.recaptchaRef.current) {
            this.state.recaptchaRef.current.reset();
        }
        this.props.createAccountAttemptCleanUp();
    }

    isValidForm() {
        let isValid = true;
        let {username, password, passwordConfirm, firstName, lastName, address} = this.state;
        let errors = {};
        if(!validEmailRegex.test(username)){
            errors['username'] = "Email address is not valid."
        }

        if(!password || password === '' ) {
            errors['password'] = "Password can't be blank."
        }
        else {
            delete errors['passwordConfirm'];
        }
        if(password !== passwordConfirm) {
            errors['passwordConfirm'] = "Passwords doesn't match."
        }
        else {
            delete errors['passwordConfirm'];
        }
        if(!firstName || firstName === '') {
            errors['firstName'] = "First name can't be blank."
        }
        else {
            delete errors['firstName'];
        }
        if(!lastName || lastName === '') {
            errors['lastName'] = "Last name can't be blank."
        }
        else {
            delete errors['lastName'];
        }
        let addressRequires = ["region", "country_id", "street", "telephone", "postcode", "city","company"]
        let availableRegions = this.state.availableRegions;
        for(let aRequiredIndex in addressRequires) {
            let required = addressRequires[aRequiredIndex];
            if(required === 'street' && address[required].length === 0) {
                errors['street_1'] = 'This field is required.';
            }
            if (required === 'region') {
                if(address.region) {
                    if (availableRegions && availableRegions.length > 0) {
                        if (address.region.region_id === "" || !address.region.region_id) {
                            errors['region'] = 'This field is required.';
                        }
                    } else {
                        if (address.region.region === "") {
                            errors['region'] = 'This field is required.';
                        }
                    }
                } else {
                    errors['region'] = 'This field is required.';
                }
            }
            if(address[required] === '' || !address[required]) {
                errors[required] = 'This field is required.';
            }
        }
        if(address['country_id'] === 'US') {
            let zipcodeRegex = /^([0-9]{5})$/;
            if(!zipcodeRegex.test(address['postcode'])) {
                errors['postcode'] = 'Not valid value for this field.'
            }
            let zipcodePlusRegex = /^([0-9]{4})$/;
            if(!zipcodePlusRegex.test(address['zip_plus'])) {
                errors['zip_plus'] = 'Zip+4 must be four digits.'
            }
        }
        if(!this.state.validCaptcha) {
            errors['captcha'] = "Please prove you're a real person by checking the box above."
        }
        this.setState({errors: errors});
        if(Object.keys(errors).length > 0) {
            window.scrollTo({top: 0, behavior: "smooth", block: 'start'})
        }
        return errors;
    }
    createAccount(event){
        event.preventDefault();
        let errors = this.isValidForm();
        let addressData = {...this.state.address};
        if(addressData.country_id === 'US') {
            addressData.postcode = `${addressData.postcode}-${addressData.zip_plus}`;
        }
        delete addressData['zip_plus'];
        if(Object.keys(errors).length == 0) {
            this.props.createAccountAttempt(
                this.state.username,
                this.state.password,
                this.state.firstName,
                this.state.lastName,
                this.state.middleName,
                addressData

            )
        }
        else {
            let inputs = document.getElementsByTagName("input");
            let selects = document.getElementsByTagName("select");
            let all = [...inputs, ...selects]
            for(let index in all) {

                let input = all[index]
                console.log(input);
                let name = input.name;
                if(errors[name]) {
                    input.classList.add("error");
                }
                else {
                    if(input.classList) {
                        input.classList.remove("error");
                    }
                }
            }
        }
    }
    goToLogin(event)
    {
        if(event != undefined) {
            event.preventDefault();
        }
        this.props.history.push('/account/login')
    }
    handleAddressChange(event){
        const { name, value } = event.target;
        console.log(value);
        let address = {...this.state.address};
        console.log();
        console.log(name);
        switch (name) {
            case 'street_1':
                address['street'][0] = value;
                break;
            case 'street_2':
                address['street'][1] = value;
                break;
            case 'default_billing':
                address.default_billing = !address.default_billing;
                break;
            case 'country_id':
                let option = event.target.options[event.target.selectedIndex];
                let countryIndex = option.getAttribute('index');
                console.log('countryIndex', countryIndex);
                if(countryIndex) {
                    address.country_id = value;
                    address.region_id = '';
                    address.region = {};
                }
                else {
                    address.country_id = '';
                    address.region_id = '';
                    address.region = {};
                }
                break;
            case "region":
                let availableRegions = this.state.availableRegions;
                if(availableRegions && availableRegions.length > 0) {
                    let regionOption = event.target.options[event.target.selectedIndex];
                    let regionIndex = regionOption.getAttribute('index');
                    let region = availableRegions[regionIndex];
                    if(region) {
                        address.region.region = region.name;
                        address.region.region_code = region.code;
                        address.region.region_id = region.id;
                        address.region_id = region.id;
                    } else {
                        address.region = {};
                        address.region_id = '';
                    }
                } else {
                    address.region = {};
                    address.region_id = 0;
                    address.region.region = value;
                }
                break;
            default:
                address[name] = value;
        }
        this.setState({
            address: address,
        });
    }
    handleChange(event) {
        let {name, value} = event.target;
        let address = {...this.state.address}
        if(name == 'firstName') {
            address.firstname = value
        }
        if(name == 'lastName') {
            address.lastname = value
        }
        this.setState({
            [event.target.name]: event.target.value,
            address: {...address}
        })
    }

    captchaVerifyCallBack(response) {
        this.setState({validCaptcha: true})
    }
    expiredCallback() {
        this.state.recaptchaRef.current.reset();
        this.setState({validCaptcha: false})
    }

    onLoadCallback() {
    }

    render(){
        let {isFetchingCreateAccount, createAccountError, createAccountSuccess} = this.props;
        let errors = this.state.errors;
        let countries = this.props.countries;
        let availableRegions = this.state.availableRegions;
        let address = {...this.state.address};
        if(isFetchingCreateAccount) {
            return (
                <div>
                    <div id="modal-create-account" className="row">
                        <div className="col-md-4 col-md-offset-4 center">
                            <div className="loading-small">
                                <i className="icon-loading"></i>
                                <span>Creating Your Account</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if(createAccountSuccess) {
            return (
                <div className="col-xs-12 center mt-20">
                    <div className="row">
                        <div className="col-xs-12">
                            <h2>You Have Successfully Registered</h2>
                            <p className="p-two white mb-20">
                                Thank you for registering an account with us! Once set up is complete,
                                you will be notified through our email system and be able to log into the site to view and order tour materials.
                                Please be sure to save <a href="mailto:orders@tourdesign.com" className="blue">orders@tourdesign.com</a> as a trusted contact to avoid emails going to spam or junk folder.
                            </p>
                            <p className="p-two white mb-20">
                                If you need to order materials immediately or have any questions,
                                please call the main line <a href="tel:323-217-1100" className="blue">323-217-1100</a> to speak to your Account Rep.
                            </p>
                            <small className="p-two white" style={{fontStyle: "italic"}}>
                                <strong>Note:</strong> Orders placed prior to account setup completion may require payment up front.
                            </small>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-10 col-sm-offset-1 col-xs-12 center">
                <div className="row">
                    <div className="col-xs-12">
                        <h2>Create Account</h2>
                        {createAccountError && <div className="alert error">
                            <p>{createAccountError}</p>
                        </div>}
                        {Object.keys(errors).length > 0 && <div className="alert error">
                            <p>Please complete the fields outlined in red below before proceeding.</p>
                            {errors && errors.zip_plus && <p>
                                {errors.zip_plus}
                            </p>
                            }
                        </div>}
                    </div>
                </div>
                <form onSubmit={this.goToLogin}>
                    <div className="row">
                        <div className="col-xs-12">
                            <input type="text" name="username"
                                   placeholder="Email Address"
                                   value={this.state.username}
                                   onChange={this.handleChange}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <input type="password" name="password"
                                   placeholder="Password"
                                   value={this.state.password}
                                   onChange={this.handleChange}/>
                        </div>
                        <div className="col-sm-6">
                            <input type="password" name="passwordConfirm"
                                   placeholder="Confirm password"
                                   value={this.state.passwordConfirm}
                                   onChange={this.handleChange}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <input type="text" name="firstName"
                                   placeholder="First Name"
                                   value={this.state.firstName}
                                   onChange={this.handleChange}/>
                        </div>
                        <div className="col-sm-6">
                            <input type="text" name="lastName"
                                   placeholder="Last Name"
                                   value={this.state.lastName}
                                   onChange={this.handleChange}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <input type="text" placeholder="Company"
                                   name='company'
                                   value={address.company}
                                   onChange={this.handleAddressChange}
                            />
                        </div>
                        <div className="col-sm-6">
                            <input type="text" placeholder="Telephone"
                                   name='telephone'
                                   value={address.telephone}
                                   onChange={this.handleAddressChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7 col-sm-6">
                            <input type="text" placeholder="Billing Address"
                                   name='street_1'
                                   value={address.street[0]? address.street[0]: ''}
                                   onChange={this.handleAddressChange}
                            />
                        </div>
                        <div className="col-md-5 col-sm-6">
                            <input type="text" placeholder="Building, Suite, etc."
                                   name="street_2"
                                   value={address.street[1]? address.street[1]: ''}
                                   onChange={this.handleAddressChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <input type="text" placeholder="City"
                                   name="city"
                                   value={address.city}
                                   onChange={this.handleAddressChange}
                            />
                        </div>
                        <div className="col-sm-6">
                            {(!availableRegions || Object.keys(availableRegions).length === 0) ?
                                <input
                                  name='region'
                                    value={(address.region && address.region.region)?address.region.region:''}
                                    placeholder={'State / Province'}
                                    onChange={this.handleAddressChange}
                                    index={-1}
                                />
                              : (
                                <select
                                  name='region'
                                  onChange={this.handleAddressChange}
                                  value={(address.region && address.region.region_code)?address.region.region_code:''}
                                >
                                    <option value={''}>State / Province</option>
                                    {availableRegions &&
                                      Object.keys(availableRegions).map((regionIndex) =>
                                        <option id={availableRegions[regionIndex].id}
                                                value={availableRegions[regionIndex].code}
                                                key={regionIndex}
                                                index={regionIndex}
                                        >
                                            {availableRegions[regionIndex].name}
                                        </option>
                                      )
                                    }
                                </select>
                              )
                            }

                        </div>
                    </div>
                    <div className="row">
                        <div className={address && address.country_id === 'US'?"col-sm-3":"col-sm-6"}>
                            <input type="text" placeholder="Zip / Postal Code"
                                   name='postcode'
                                   value={address.postcode}
                                   onChange={this.handleAddressChange}
                            />
                        </div>
                        {address && address.country_id === 'US' && <div className="col-sm-3"
                                                                        style={{
                                                                            textAlign:' left'
                                                                        }}
                        >
                            <input type="text" placeholder="Zip + 4"
                                   style={{
                                       width: "80%",
                                       display: 'inline'
                                   }}
                                   name='zip_plus'
                                   value={address.zip_plus}
                                   onChange={this.handleAddressChange}
                            />
                            <a href="https://tools.usps.com/zip-code-lookup.htm"
                               title="Unsure what your zip+4 is? Click here to look it up."
                               style={{display: ''}}
                               target="_blank"
                            >
                                <FontAwesomeIcon
                                  icon={icon({name: 'circle-question'})}
                                  style={{
                                      color: 'white',
                                      marginLeft: '5px'
                                  }}
                                />
                            </a>
                        </div>

                        }
                        <div className="col-sm-6">
                            <select
                                value={address.country_id}
                                name='country_id'
                                onChange={this.handleAddressChange}
                            >

                                <option >Select Country</option>
                                {countries && Object.keys(countries).map((index) =>
                                    <option
                                        id={countries[index].id}
                                        value={countries[index].id}
                                        key={index}
                                        index={index}
                                    >
                                        {countries[index].full_name_english}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    {/*<input type="text" name="middleName"*/}
                           {/*placeholder="Middle Name"*/}
                           {/*value={this.state.middleName}*/}
                           {/*onChange={this.handleChange}/>*/}
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <div id="recaptcha"></div>
                            <Recaptcha
                                sitekey={CAPTCHA_SITE_KEY}
                                verifyCallback={this.captchaVerifyCallBack}
                                expiredCallback={this.expiredCallback}
                                render="explicit"
                                onloadCallback={this.onLoadCallback}
                                type="image"
                                ref={this.state.recaptchaRef}
                                size='compat'
                                className="test"
                                elementID="recaptcha"
                            />
                            {errors['captcha'] && <div className="recaptcha-error-wrapper">
                                <div className="alert error">
                                    <p>{errors['captcha']} </p>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-lg-offset-4 col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1 center">
                            <button type="submit" className="button small blue-white" onClick={this.createAccount}>
                                Create Account
                            </button>
                        </div>
                        <div className="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-8 col-xs-offset-2 center">
                            <button id="button-cancel-create-account" type="submit"
                                    className="button-text white" onClick={this.goToLogin}>I
                                already have an account.
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )

    }

}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAccountForm))
