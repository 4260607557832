import React from "react"
import {CDN_URL, FIELDS_CODE} from "../../actions/Constants";
import CustomCheckbox from "../CustomCheckbox";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {getClassError} from "../Product/commonFunctions";
import ErrorMessage from "../ErrorMessage";

export default class ShowsFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            selectedDays: [],
            selectDate: false,
        }
        this.handleDayClick = this.handleDayClick.bind(this);
        this.showDateOnFocus = this.showDateOnFocus.bind(this);
        this.closeDatePicker = this.closeDatePicker.bind(this);
        this.convertToFullDate = this.convertToFullDate.bind(this);
        this.setSelectedDaysFromShow = this.setSelectedDaysFromShow.bind(this);
    }

    componentDidMount() {
        this.setState({
            errors: this.props.errors
        })
        this.setSelectedDaysFromShow()
    }
    setSelectedDaysFromShow()
    {
        let showInformation = this.props.showInformation;
        let showDates = showInformation.show_date;
        if(showDates) {
            showDates = showDates.split(',');
        }
        else {
            showDates = [];
        }
        let fullDates = this.convertToFullDate(showDates);
        this.setState({
            selectedDays: fullDates
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.showInformation.show_date !== this.props.showInformation.show_date) {
            this.setSelectedDaysFromShow()
        }
        if(prevProps.errors !== this.props.errors) {
            this.setState({
                errors: this.props.errors
            })
        }
    }
    convertToFullDate(dates) {
        let datesToReturn = []
        for(let index in dates) {
            let dateStr = dates[index];
            if(typeof dateStr === "string") {
                dateStr = dateStr.trim();
                let dateArray = dateStr.split('/');
                let date = new Date();
                date.setMonth(dateArray[0] - 1);
                date.setDate(dateArray[1]);
                date.setFullYear(dateArray[2])
                datesToReturn.push(date);
            }
        }
        return datesToReturn;
    }
    convertToShortDate(dates) {
        let datesToReturn = []
        for(let index in dates) {
            let dateStr = dates[index];
            if(DateUtils.isDate(dateStr)) {
                let date = new Date(dateStr);
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                datesToReturn.push(`${month}/${day}/${year}`);
            }
        }

        return datesToReturn.join(', ');
    }
    handleDayClick(day, {selected}, e) {
        let { selectedDays } = this.state;
        let shiftPressed = e.shiftKey;
        if(day > new Date()) {
            if (selected) {
                const selectedIndex = selectedDays.findIndex(selectedDay =>
                    DateUtils.isSameDay(selectedDay, day)
                );
                selectedDays.splice(selectedIndex, 1);
            } else {
                if(shiftPressed || selectedDays.length == 0) {
                    selectedDays.push(day);
                }
                else {
                    if(!shiftPressed) {
                        selectedDays = [];
                        selectedDays.push(day);
                    }
                }
                if(!shiftPressed) {
                    this.setState({
                        selectDate: false
                    })
                }
            }
            this.props.handleShowDate(this.convertToShortDate(selectedDays));
            this.setState({
                selectedDays: selectedDays,
            });
        }
    }
    showDateOnFocus(event) {
        this.setState({
            selectDate: !this.state.selectDate
        })
    }
    closeDatePicker() {
        this.setState({
            selectDate: false
        })
    }

    ticketLocationClass(showIndex) {
        let className = [];
        let errors = this.state.errors;
        if(errors && errors.indexOf(FIELDS_CODE.ticket_locations) != -1) {
            className.push('error')
        }
        if(showIndex > 0) {
            className.push('grey-class');
        }
        return className;
    }
    displayField(fieldCode) {
        let allFields = this.props.fieldsToShow;
        if(allFields.indexOf(fieldCode) !== -1) {
            return true;
        }
        return false;
    }
    render() {
        let {showindex, showInformation, pkgArtForm} = this.props;
        let errors = this.state.errors;
        let selectDate = this.state.selectDate;
        let ticketLocationClasses = this.ticketLocationClass(showindex);
        if(pkgArtForm) {
            return (
            <div>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label className="required">Show Date</label>
                        <div className="icon" onClick={this.showDateOnFocus}>
                            <input type="text" autoComplete="off"
                                   readOnly={'readonly'}
                                   name={"showDate_" + showindex}
                                   value={showInformation.show_date}
                                   className={(errors && errors.indexOf(FIELDS_CODE.show_date) != -1 )?'error':''}
                                   onChange={this.props.handleChange}
                                   onClick={this.showDateOnFocus}
                                   code={FIELDS_CODE.show_date}
                            />
                            <i><img src={`${CDN_URL}assets/icon-date-select.svg`} alt="Select Date"/></i>
                        </div>
                        <div id={"day-picker-container"}>
                            {selectDate && <DayPicker
                                selectedDays={this.state.selectedDays}
                                onDayClick={this.handleDayClick}
                                todayButton="Done"
                                onTodayButtonClick={this.closeDatePicker}
                                disabledDays={{ before: new Date() }}
                            />}
                        </div>
                        <small>For multiple dates, hold down shift and select. We will
                            not automatically mention days of the week with show date unless it
                            falls on a Friday or Saturday.</small>
                    </div>
                    <div className="form-group col-sm-6">
                        <label className="required">Venue Name</label>
                        <input type="text" name={"venueName_" + showindex} value={showInformation.venue_name}
                               className={(errors && errors.indexOf(FIELDS_CODE.venue_name) != -1)?'error':''}
                               onChange={this.props.handleChange}
                               code={FIELDS_CODE.venue_name}
                        />
                        <small>We will only include city name in voiceover with the venue if you
                            include it here.</small>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label className="required">City / Market Name </label>
                        <input type="text" name={"cityMarketName_" + showindex}
                               className={(errors && errors.indexOf(FIELDS_CODE.city_market_name) != -1)?'error':''}
                               value={showInformation.city_market_name}
                               onChange={this.props.handleChange}
                               code={FIELDS_CODE.city_market_name}
                        />
                        <small>If needed in voiceover, please also include in venue name
                            field.</small>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-12">
                        <label className="">Special Guest(s)</label>
                        <input type="text" name={"especialGuests_" + showindex} value={showInformation.special_guests}
                               className={(errors && errors.indexOf(FIELDS_CODE.special_guests) != -1)?'error':''}
                               onChange={this.props.handleChange}
                               code={FIELDS_CODE.special_guests}
                        />
                        <small>If none, please type “NA” or “None” or “Solo</small>
                    </div>
                </div>
            </div>
            )
        }
        return (

            <div>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label className="required">Show Date</label>
                        <div className="icon" onClick={this.showDateOnFocus}>
                            <input type="text" autoComplete="off"
                                   readOnly={'readonly'}
                                   name={"showDate_" + showindex}
                                   value={showInformation.show_date}
                                   className={(errors && errors.indexOf(FIELDS_CODE.show_date) != -1 )?'error':''}
                                   onChange={this.props.handleChange}
                                   onClick={this.showDateOnFocus}
                                   code={FIELDS_CODE.show_date}
                            />
                            <i><img src={`${CDN_URL}assets/icon-date-select.svg`} alt="Select Date"/></i>
                        </div>
                        <div id={"day-picker-container"}>
                        {selectDate && <DayPicker
                            selectedDays={this.state.selectedDays}
                            onDayClick={this.handleDayClick}
                            todayButton="Done"
                            onTodayButtonClick={this.closeDatePicker}
                            disabledDays={{ before: new Date() }}
                        />}
                        </div>
                        <small>For multiple dates, hold down shift and select. We will
                            not automatically mention days of the week with show date unless it
                            falls on a Friday or Saturday.</small>
                    </div>
                    <div className="form-group col-sm-6">
                        <label>Show Time(s)</label>
                        <input type="text" name={"showTimes_" + showindex} value={showInformation.show_time}
                               onChange={this.props.handleChange}
                               code={FIELDS_CODE.show_time}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className={`form-group col-sm-6
                            ${this.displayField(FIELDS_CODE.venue_capacity.code)?'col-lg-6':'col-lg-12'}
                            `
                        }
                    >
                        <label className="required">Special Guest(s)</label>
                        <input type="text" name={"especialGuests_" + showindex} value={showInformation.special_guests}
                               className={(errors && errors.indexOf(FIELDS_CODE.special_guests) !== -1)?'error':''}
                               onChange={this.props.handleChange}
                               code={FIELDS_CODE.special_guests}
                        />
                        <small>If none, please type “NA” or “None” or “Solo</small>
                    </div>
                    {this.displayField(FIELDS_CODE.venue_capacity.code) &&
                    <div className="form-group col-sm-6">
                        <label className={(errors.indexOf(FIELDS_CODE.venue_capacity.code) !== -1)?'error required':'required' }>Venue Capacity</label>
                        {errors.indexOf(FIELDS_CODE.venue_capacity.code) !== -1 && <div className="mt-10">
                            <ErrorMessage
                                message="Please choose an option below for Venue Capacity."
                            />
                        </div>}
                        <ul className="inline">
                            <li>
                                <CustomCheckbox checked={showInformation.venue_capacity === FIELDS_CODE.venue_capacity.sku.venue_capacity_3000}
                                                label='3000+'
                                                type="radio"
                                                name={"venueCapacity_" + showindex}
                                                onchange={this.props.handleChangeCheckAndRadio}
                                                feedBackInfo={{
                                                    value: FIELDS_CODE.venue_capacity.sku.venue_capacity_3000,
                                                    code: FIELDS_CODE.venue_capacity.code
                                                }}
                                />
                            </li>
                            <li>
                                <CustomCheckbox checked={showInformation.venue_capacity === FIELDS_CODE.venue_capacity.sku.venue_capacity_1000_2999}
                                                label='1000 - 2999'
                                                type="radio"
                                                name={"venueCapacity_" + showindex}
                                                onchange={this.props.handleChangeCheckAndRadio}
                                                feedBackInfo={{
                                                    value: FIELDS_CODE.venue_capacity.sku.venue_capacity_1000_2999,
                                                    code: FIELDS_CODE.venue_capacity.code
                                                }}
                                />
                            </li>
                            <li>
                                <CustomCheckbox checked={showInformation.venue_capacity === FIELDS_CODE.venue_capacity.sku.venue_capacity_under_1000}
                                                label='under 1000'
                                                type="radio"
                                                name={"venueCapacity_" + showindex}
                                                onchange={this.props.handleChangeCheckAndRadio}
                                                feedBackInfo={{
                                                    value: FIELDS_CODE.venue_capacity.sku.venue_capacity_under_1000,
                                                    code: FIELDS_CODE.venue_capacity.code
                                                }}
                                />
                            </li>
                        </ul>
                    </div>}
                </div>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label className="required">Venue Name</label>
                        <input type="text" name={"venueName_" + showindex} value={showInformation.venue_name}
                               className={(errors && errors.indexOf(FIELDS_CODE.venue_name) !== -1)?'error':''}
                               onChange={this.props.handleChange}
                               code={FIELDS_CODE.venue_name}
                        />
                        <small>We will only include city name in voiceover with the venue if you
                            include it here.</small>

                        <label className="required">City / Market Name </label>
                        <input type="text" name={"cityMarketName_" + showindex}
                               className={(errors && errors.indexOf(FIELDS_CODE.city_market_name) != -1)?'error':''}
                               value={showInformation.city_market_name}
                               onChange={this.props.handleChange}
                               code={FIELDS_CODE.city_market_name}
                        />
                        <small>If needed in voiceover, please also include in venue name
                            field.</small>
                    </div>
                    <div className="form-group col-sm-6">
                        <label className="required">Ticket Locations</label>
                        <textarea type="text" name={"ticketLocations_" + showindex}
                                  className={ticketLocationClasses.join(' ')}
                                  value={showInformation.ticket_locations}
                                  onChange={this.props.handleChange}
                                  code={FIELDS_CODE.ticket_locations}
                        ></textarea>
                        {this.displayField(FIELDS_CODE.venue_capacity.code) && <ul>
                            <li>
                                <CustomCheckbox checked={showInformation.mention_reserved_seats}
                                                label='Mention Reserved Seats?'
                                                type="checkbox"
                                                name={"mentionReservedSeats_" + showindex}
                                                onchange={this.props.handleChangeCheckAndRadio}
                                                feedBackInfo={{
                                                    value: 1,
                                                    code: FIELDS_CODE.mentions.sku.mention_reserved_seats,
                                                    'field-code': FIELDS_CODE.mentions.code
                                                }}
                                />
                            </li>
                            <li>
                                <CustomCheckbox checked={showInformation.mention_general_admission}
                                                label='Mention General Admission?'
                                                type="checkbox"
                                                name={"mentionGeneralAdmission_" + showindex}
                                                onchange={this.props.handleChangeCheckAndRadio}
                                                feedBackInfo={{
                                                    value: 1,
                                                    code: FIELDS_CODE.mentions.sku.mention_general_admission,
                                                    'field-code': FIELDS_CODE.mentions.code
                                                }}
                                />
                            </li>
                        </ul>}
                    </div>
                </div>
            </div>
        )
    }
}

// export default connect(null, null)(ShowsFields);
