export let getClassError = (fieldCode, customErrors) => {
    if(customErrors && customErrors.indexOf(fieldCode) != -1) {
        return 'error'
    }
    return '';
}
/*
Function to get field label from a product.
 */
export const getCustomizableOptionLabel = (fieldCode, product, checkbox_option_sku = null) => {

    let options = product.options;
    let label = '';
    options.map(option => {
        if(option.extension_attributes && option.extension_attributes.option_code === fieldCode) {
            if((option.type === 'checkbox' || option.type === 'radio') && checkbox_option_sku) {

                let checkboxValues = option.values;
                checkboxValues.map((checkboxValue) => {
                    if(checkboxValue.sku === checkbox_option_sku) {
                        label = checkboxValue.title;
                    }
                })
            } else {
                label = option.title;
            }
        }
    })
    return label;
}
